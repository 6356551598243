import { API_TYPE } from '../lib/API/ApiClientOptions';
import { setEnvConfig } from '../utils/envUtils';
import { loadItemsFromCookie } from '../utils/cookieUtils';

type AuthProviders = 'auth0' | 'simple';

export const SUPPORT_LINK: string = 'https://bit.ly/opal-slack';
export const CONNECT_PDP_LINK: string = 'https://docs.permit.io/concepts/pdp/';
const TENANT_ID_KEY = 'tenantId';
const ENV_ID_KEY = 'envId';
const ORGANIZATION_ID_KEY = 'organizationId';
export const CONNECT_SDK_SCREEN_URL: string = '/connect-sdk';
export const CONNECT_SDK_SCREEN_URL2: string = '/get-started/connect-an-sdk';
declare global {
  interface Window {
    _env_: any;
  }
}

export const SIDEBAR_MIN_WIDTH = 74;
export const SIDEBAR_MAX_WIDTH = 250;

export interface RouteConfig {
  title: string;
  path: string;
  icon: string;
  // other routes that would redirect to this one
  redirects?: string[];
  componentName: string;
  // nested under tenant context
  isTenantLevel?: boolean;
  // nested under project-environment context
  isEnvLevel?: boolean;
}
export interface ConfigOptions {
  dev: { isDebug: Boolean; isProd: Boolean };
  app: {
    title: string;
    appearance: {
      isDark: boolean;
      isEmbedded: boolean;
      hasAppBar: boolean;
      hasNavigationDrawer: boolean;
    };
  };
  backend: { url: string; type: API_TYPE };
  authentication: { provider: AuthProviders };
  auth0: {
    domain: string;
    clientId: string;
    audience: string;
  };
  sentry: {
    dsn?: string;
    env?: string;
    release?: string;
  };
  queryParamKeys: { tenantId: string; customTenantId: string };
  urlPathParamKeys: { tenantId: string; envId: string; organizationId: string };
  cookieKeys: {
    activeEnvironmentId: string;
    activeProjectId: string;
    activeTenantId: string;
    activeOrganizationId: string;
    isDarkTheme: string;
  };
  itemsToCookie: Record<string, string>;
}

/**
 * Config override order:
 * 1. Hardcoded in object below
 * 2. ENV variables
 * 3. Cookie values (As indicated by itemsToCookie)
 * 4. URL-query params (loaded by AppConfigManager)
 */
const Config: ConfigOptions = {
  dev: {
    isDebug: true,
    isProd: (window?._env_?.IS_PROD && window?._env_?.IS_PROD !== 'false') || false,
  },
  app: {
    title: 'Permit.io',
    appearance: {
      isDark: false,
      isEmbedded: false,
      hasAppBar: true,
      hasNavigationDrawer: true,
    },
  },
  backend: {
    url:
      // TODO: process?.env?.REACT_APP_BACKEND_URL ||
      window?._env_?.BACKEND_URL || 'http://localhost:8000',
    type: API_TYPE.REST,
  },
  authentication: {
    provider: 'auth0',
  },
  auth0: {
    domain: window?._env_?.AUTH0_DOMAIN || 'acalla-dev.us.auth0.com',
    clientId: window?._env_?.AUTH0_CLIENT_ID || 'e7EgZfPpLfa5vnloGpdLsbvxBEs7v3cn',
    audience: window?._env_?.AUTH0_AUDIENCE || 'https://api.acalla.com/v1/',
  },
  sentry: {
    dsn: window?._env_?.SENTRY_DSN,
    env: window?._env_?.SENTRY_ENV,
    release: window?._env_?.SENTRY_RELEASE,
  },
  // Query string - e.g. /?tenantId="11111"
  queryParamKeys: {
    // tenant ID in our own model
    tenantId: TENANT_ID_KEY,
    // tenant ID in the customer's proprietary model
    customTenantId: 'customTenantId',
  },
  // param paths - e.g. /tenant/:tenantId <-  /tenant/11111
  urlPathParamKeys: {
    // tenant ID in our own model
    tenantId: TENANT_ID_KEY,
    envId: ENV_ID_KEY,
    organizationId: ORGANIZATION_ID_KEY,
  },
  cookieKeys: {
    activeTenantId: 'ACTIVE_TENANT_ID',
    activeEnvironmentId: 'ACTIVE_ENV_ID',
    activeProjectId: 'ACTIVE_PROJECT_ID',
    activeOrganizationId: 'ACTIVE_ORGANIZATION_ID',
    isDarkTheme: 'IS_DARK_THEME',
  },
  /**
   * Values that will be loaded into config from cookie paths
   * {CookieKey:ConfigPath}
   */
  itemsToCookie: {
    IS_DARK_THEME: 'app.appearance.isDark',
  },
};

// Override config values using REACT_APP_CONFIG_* env variables
// e.g. REACT_APP_CONFIG_authentication_provider=simple yarn start
setEnvConfig(Config);

// Override config values (As indicated by Config.itemsToCookie) from cookie store
loadItemsFromCookie(Config, Config.itemsToCookie);

export default Config;
